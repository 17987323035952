import React from "react";
import ReactDOM from 'react-dom'
import Main from "./App";


const temp = ReactDOM.createRoot(document.getElementById('root'))
temp.render(
 <Main/> 
)

